// routes
import {
  PATH_ADMIN,
  PATH_CHECK_CHART,
  PATH_COACHING,
  PATH_DASHBOARD,
  PATH_LEARN,
  PATH_LEARNING_SCIENCE,
} from './paths';
// components
import { AuthUser, Role } from '@common/@types/auth';
import { BlacklistFunction } from '@common/@types/nav';
import Iconify from '@common/components/Iconify';
import SvgIconStyle from '@common/components/SvgIconStyle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BallotIcon from '@mui/icons-material/Ballot';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import MoneyIcon from '@mui/icons-material/LocalAtm';
import SchoolIcon from '@mui/icons-material/School';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { SiteStrings } from 'common/@types/siteStrings';

// ----------------------------------------------------------------------

export function blockUserNotInRoles(...roles: Role[]): BlacklistFunction {
  return (user: AuthUser) => roles.every((role) => user?.alphaAuthAttributes?.role !== role);
}

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

export const getNavConfig = (siteStrings: SiteStrings) => [
  {
    subheader: 'learn',
    items: [
      {
        title: 'my required learning',
        path: PATH_LEARN.general.recommendations,
        icon: <SchoolIcon />,
      },
    ],
  },
  {
    subheader: 'dashboard',
    items: [
      {
        title: 'my daily activity',
        path: PATH_DASHBOARD.general.daily_activity.day,
        icon: (
          <Iconify
            icon={
              siteStrings.nav.dashboard.myActivity.icon ?? 'mdi:clipboard-check-multiple-outline'
            }
            width={32}
            height={32}
          />
        ),
        children: [
          {
            title: 'daily',
            path: PATH_DASHBOARD.general.daily_activity.day,
            icon: siteStrings.nav.dashboard.myActivity.daily?.icon ? (
              <Iconify icon={siteStrings.nav.dashboard.myActivity.daily.icon} />
            ) : undefined,
          },
          {
            title: 'weekly',
            path: PATH_DASHBOARD.general.daily_activity.week,
            icon: siteStrings.nav.dashboard.myActivity.weekly?.icon ? (
              <Iconify icon={siteStrings.nav.dashboard.myActivity.weekly.icon} />
            ) : undefined,
          },
          {
            title: 'monthly',
            path: PATH_DASHBOARD.general.daily_activity.month,
            icon: siteStrings.nav.dashboard.myActivity.monthly?.icon ? (
              <Iconify icon={siteStrings.nav.dashboard.myActivity.monthly.icon} />
            ) : undefined,
          },
        ],
      },
      {
        title: 'earnings',
        path: PATH_DASHBOARD.general.learn_to_earn,
        icon: <MoneyIcon />,
        blacklistFunction: (user: AuthUser) => {
          const allowedCampuses = ['4', '9']; // AI-GEN - Cursor - GPT4
          if (
            user?.alphaAuthAttributes?.role === Role.STUDENT && // AI-GEN - Cursor - GPT4
            allowedCampuses.includes(user?.alphaAuthAttributes?.campusId)
          ) {
            return false; // AI-GEN - Cursor - GPT4
          } // AI-GEN - Cursor - GPT4
          if (user?.alphaAuthAttributes?.role === Role.ADMIN) {
            return false; // AI-GEN - Cursor - GPT4
          } // AI-GEN - Cursor - GPT4
          return true; // AI-GEN - Cursor - GPT4
        }, // AI-GEN - Cursor - GPT4
      },
      {
        title: 'Alpha Coin',
        path: PATH_DASHBOARD.general.leaderboards.alpha_coin,
        icon: <MoneyIcon />,
        blacklistFunction: (user: AuthUser) => {
          const allowedCampuses = ['10']; // AI-GEN - Cursor - GPT4
          if (
            user?.alphaAuthAttributes?.role === Role.STUDENT && // AI-GEN - Cursor - GPT4
            allowedCampuses.includes(user?.alphaAuthAttributes?.campusId)
          ) {
            return false; // AI-GEN - Cursor - GPT4
          } // AI-GEN - Cursor - GPT4
          if (user?.alphaAuthAttributes?.role === Role.ADMIN) {
            return false; // AI-GEN - Cursor - GPT4
          } // AI-GEN - Cursor - GPT4
          return true; // AI-GEN - Cursor - GPT4
        }, // AI-GEN - Cursor - GPT4
      },
      {
        title: 'my session',
        path: PATH_DASHBOARD.general.session,
        icon: getIcon('ic_analytics'),
        blacklistFunction: (user: AuthUser) => true,
      },
      {
        title: 'My TimeBack',
        path: PATH_DASHBOARD.general.leaderboards.timeback,
        icon: <AccessTimeIcon />,
        blacklistFunction: (user: AuthUser) => {
          if (
            user?.alphaAuthAttributes?.role === Role.ADMIN ||
            user?.alphaAuthAttributes?.role === Role.GUIDE
          ) {
            return false;
          } else {
            return true;
          }
        },
      },
      {
        title: 'speedrun competition',
        path: PATH_DASHBOARD.general.leaderboards.speedrun_competition,
        icon: <MoneyIcon />,
        blacklistFunction: (user: AuthUser) => {
          if (user?.alphaAuthAttributes?.role === Role.ADMIN) {
            return false; // AI-GEN - Cursor - GPT4
          } // AI-GEN - Cursor - GPT4
          const studentGroup = user?.alphaAuthAttributes?.studentGroup?.toLowerCase(); // AI-GEN - Cursor - GPT4
          if (studentGroup === 'speedrun' || studentGroup === 'mock student') {
            return false; // AI-GEN - Cursor - GPT4
          } // AI-GEN - Cursor - GPT4
          return true; // AI-GEN - Cursor - GPT4
        }, // AI-GEN - Cursor - GPT4
      },
      {
        title: 'leaderboards',
        path: PATH_DASHBOARD.general.leaderboards.units_mastered,
        icon: <Iconify icon={'ant-design:trophy-outlined'} width={32} height={32} />,
        children: [
          {
            title: '2hr sessions',
            path: PATH_DASHBOARD.general.leaderboards.sessions_2hr,
          },
          {
            title: 'mastered units',
            path: PATH_DASHBOARD.general.leaderboards.units_mastered,
            icon: siteStrings.nav.dashboard.leaderboards?.masteredUnits?.icon ? (
              <Iconify icon={siteStrings.nav.dashboard.leaderboards.masteredUnits.icon} />
            ) : undefined,
            blacklistFunction: (user: AuthUser) => {
              const studentGroup = user?.alphaAuthAttributes?.studentGroup?.toLowerCase(); // AI-GEN - Cursor - GPT4
              if (studentGroup === 'speedrun') {
                return true; // AI-GEN - Cursor - GPT4
              } // AI-GEN - Cursor - GPT4
              return false; // AI-GEN - Cursor - GPT4
            }, // AI-GEN - Cursor - GPT4
          },
          {
            title: 'beast of the week',
            path: PATH_DASHBOARD.general.leaderboards.beast_of_the_week,
            blacklistFunction: (user: AuthUser) => {
              if (
                user?.alphaAuthAttributes?.role === Role.STUDENT &&
                ['1', '3', '5'].includes(user?.alphaAuthAttributes?.campusId)
              ) {
                return false; // AI-GEN - Cursor - GPT4
              } // AI-GEN - Cursor - GPT4
              if (
                [Role.ADMIN, Role.GUIDE].includes(user?.alphaAuthAttributes?.role ?? Role.UNDEFINED)
              ) {
                return false; // AI-GEN - Cursor - GPT4
              } // AI-GEN - Cursor - GPT4
              return true; // AI-GEN - Cursor - GPT4
            }, // AI-GEN - Cursor - GPT4
          },
          {
            title: 'learning blitz',
            path: PATH_DASHBOARD.general.leaderboards.learning_blitz,
            blacklistFunction: (user: AuthUser) => {
              // Explicitly check if the user's role is either 'ADMIN' or 'GUIDE' // AI-GEN - Cursor - GPT4
              const role = user?.alphaAuthAttributes?.role; // AI-GEN - Cursor - GPT4
              // If the role is 'ADMIN' or 'GUIDE', return false to not blacklist the user // AI-GEN - Cursor - GPT4
              if (role === Role.ADMIN || role === Role.GUIDE) {
                return false; // AI-GEN - Cursor - GPT4
              } // AI-GEN - Cursor - GPT4
              // Otherwise, return true to blacklist the user // AI-GEN - Cursor - GPT4
              return true; // AI-GEN - Cursor - GPT4
            }, // AI-GEN - Cursor - GPT4
          },
          {
            title: 'learn to give', // AI-GEN - Cursor - GPT4
            path: PATH_DASHBOARD.general.leaderboards.learning_to_give, // AI-GEN - Cursor - GPT4
            blacklistFunction: (user: AuthUser) => {
              if (
                user?.alphaAuthAttributes?.role === Role.STUDENT &&
                ['1', '3'].includes(user?.alphaAuthAttributes?.campusId)
              ) {
                return false; // AI-GEN - Cursor - GPT4
              } // AI-GEN - Cursor - GPT4
              if (
                [Role.ADMIN, Role.GUIDE].includes(user?.alphaAuthAttributes?.role ?? Role.UNDEFINED)
              ) {
                return false; // AI-GEN - Cursor - GPT4
              } // AI-GEN - Cursor - GPT4
              return true; // AI-GEN - Cursor - GPT4
            }, // AI-GEN - Cursor - GPT4
          },
          // AI-GEN START - JB AI Assistant - GPT4
          {
            title: 'Math Facts Challenge',
            path: PATH_DASHBOARD.general.leaderboards.math_facts_challenge,
            blacklistFunction: (user: AuthUser) => {
              if (
                user?.alphaAuthAttributes?.role === Role.STUDENT &&
                ['1', '3'].includes(user?.alphaAuthAttributes?.campusId)
              ) {
                return false; // AI-GEN - Cursor - GPT4
              } // AI-GEN - Cursor - GPT4
              if (
                [Role.ADMIN, Role.GUIDE].includes(user?.alphaAuthAttributes?.role ?? Role.UNDEFINED)
              ) {
                return false; // AI-GEN - Cursor - GPT4
              } // AI-GEN - Cursor - GPT4
              return true; // AI-GEN - Cursor - GPT4
            }, // AI-GEN - Cursor - GPT4
          },
          // AI-GEN END
        ],
        blacklistFunction: (user: AuthUser) => {
          const allowedCampuses = ['1', '3', '5', '6']; // AI-GEN - Cursor - GPT4
          if (
            user?.alphaAuthAttributes?.role === Role.STUDENT &&
            allowedCampuses.includes(user?.alphaAuthAttributes?.campusId ?? '')
          ) {
            return false; // Indicates campus is allowed // AI-GEN - Cursor - GPT4
          } // AI-GEN - Cursor - GPT4
          if (
            [Role.ADMIN, Role.GUIDE].includes(user?.alphaAuthAttributes?.role ?? Role.UNDEFINED)
          ) {
            return false; // Indicates role is allowed // AI-GEN - Cursor - GPT4
          } // AI-GEN - Cursor - GPT4
          return true; // Otherwise, not allowed // AI-GEN - Cursor - GPT4
        }, // AI-GEN - Cursor - GPT4
      },
      {
        title: 'my accountability',
        path: PATH_DASHBOARD.general.accountability,
        icon: <Iconify icon={'mdi:thought-bubble-outline'} width={32} height={32} />,
        blacklistFunction: (user: AuthUser) => true,
      },
    ],
  },
  {
    subheader: 'coaching',
    items: [
      {
        title: 'summaries',
        path: PATH_COACHING.general.index,
        icon: <Iconify icon={'mdi:clipboard-check-multiple-outline'} width={32} height={32} />,
        children: [
          {
            title: 'daily',
            path: PATH_COACHING.general.index,
            icon: siteStrings.nav.dashboard.myActivity.daily?.icon ? (
              <Iconify icon={siteStrings.nav.dashboard.myActivity.daily.icon} />
            ) : undefined,
          },
          // {
          //   title: 'weekly',
          //   path: PATH_COACHING.general.week,
          //   icon: siteStrings.nav.dashboard.myActivity.weekly?.icon ? (
          //     <Iconify icon={siteStrings.nav.dashboard.myActivity.weekly.icon} />
          //   ) : undefined,
          // },
        ],
        blacklistFunction: (user: AuthUser) =>
          user?.alphaAuthAttributes?.role !== Role.ADMIN &&
          !(
            user?.alphaAuthAttributes?.role === Role.GUIDE &&
            ['1', '3', '5'].includes(user?.alphaAuthAttributes?.campusId ?? '')
          ),
      },
    ],
  },
  {
    subheader: siteStrings.nav.checkCharts.subheader ?? 'check charts',
    items: [
      {
        title: siteStrings.nav.checkCharts.checkCharts.title ?? 'check charts',
        path: PATH_CHECK_CHART.root,
        icon: <Iconify icon={siteStrings.nav.checkCharts.checkCharts.icon ?? 'mdi:chart-box'} />,
        blacklistFunction: (user: AuthUser) => {
          if (
            ['1', '3', '5', '6'].includes(user?.alphaAuthAttributes?.campusId ?? '') &&
            user?.alphaAuthAttributes?.role === Role.STUDENT
          ) {
            return false; // AI-GEN - Cursor - GPT4
          } // AI-GEN - Cursor - GPT4
          if (user?.alphaAuthAttributes?.role === Role.PARENT) {
            return false; // AI-GEN - Cursor - GPT4
          } // AI-GEN - Cursor - GPT4
          if (
            user?.alphaAuthAttributes?.role === Role.ADMIN ||
            user?.alphaAuthAttributes?.role === Role.GUIDE
          ) {
            return false; // AI-GEN - Cursor - GPT4
          } // AI-GEN - Cursor - GPT4
          return true; // AI-GEN - Cursor - GPT4
        }, // AI-GEN - Cursor - GPT4
      },
      {
        title: siteStrings.nav.checkCharts.checkChartsStudent.title ?? 'check charts - student',
        path: PATH_CHECK_CHART.general.student,
        icon: (
          <Iconify icon={siteStrings.nav.checkCharts.checkChartsStudent.icon ?? 'mdi:chart-box'} />
        ),

        // Permanent: only show to guides/admins; students/parents load their view from above link
        blacklistFunction: (user: AuthUser) => {
          if (
            user?.alphaAuthAttributes?.role === Role.ADMIN ||
            user?.alphaAuthAttributes?.role === Role.GUIDE
          ) {
            return false; // AI-GEN - Cursor - GPT4
          } // AI-GEN - Cursor - GPT4
          return true; // AI-GEN - Cursor - GPT4
        }, // AI-GEN - Cursor - GPT4
      },
    ],
  },
  {
    subheader: 'learning science',
    items: [
      {
        title: 'Session Snapshot',
        path: PATH_LEARNING_SCIENCE.general.snapshot,
        icon: <CameraAltIcon />,
        blacklistFunction: (user: AuthUser) => {
          const role = user?.alphaAuthAttributes?.role;

          // Safety
          if (!role) return true;

          // Enabled for guides/admins/parents
          if ([Role.ADMIN, Role.GUIDE, Role.PARENT].includes(role)) {
            return false;
          }

          // Hidden for all L4s
          if (user?.alphaAuthAttributes?.levels.some((level) => level.displayName === 'L4')) {
            return true;
          }

          // Enabled only for Austin & Brownsville
          return (
            user?.alphaAuthAttributes?.campusId !== '1' &&
            user?.alphaAuthAttributes?.campusId !== '3'
          );
        },
      },
      {
        title: 'my learning report',
        path: PATH_LEARNING_SCIENCE.general.learning_report,
        icon: <Iconify icon={'mdi:clipboard-check-multiple-outline'} width={32} height={32} />,
        blacklistFunction: (user: AuthUser) => {
          const role = user?.alphaAuthAttributes?.role ?? Role.UNDEFINED; // AI-GEN - Cursor - GPT4
          const campusId = user?.alphaAuthAttributes?.campusId ?? ''; // AI-GEN - Cursor - GPT4
          if (role === Role.STUDENT && [0, 1, 3, 5].includes(Number(campusId))) {
            return false; // AI-GEN - Cursor - GPT4
          } // AI-GEN - Cursor - GPT4
          if ([Role.ADMIN, Role.GUIDE, Role.PARENT].includes(role)) {
            return false; // AI-GEN - Cursor - GPT4
          } // AI-GEN - Cursor - GPT4
          return true; // AI-GEN - Cursor - GPT4
        }, // AI-GEN - Cursor - GPT4
      },
      {
        title: 'Goals',
        path: PATH_LEARNING_SCIENCE.general.goals,
        icon: <FlagCircleIcon />,
      },
      {
        title: 'my MAP report',
        path: PATH_LEARNING_SCIENCE.general.map,
        icon: <Iconify icon={siteStrings.nav.learningScience.myMapReport.icon ?? 'mdi:school'} />,
      },
      {
        title: 'panorama report',
        path: PATH_LEARNING_SCIENCE.general.panorama,
        icon: <BallotIcon />,
        blacklistFunction: (user: AuthUser) => {
          const role = user?.alphaAuthAttributes?.role ?? Role.UNDEFINED; // AI-GEN - Cursor - GPT4
          if ([Role.ADMIN, Role.PARENT, Role.GUIDE].includes(role)) {
            return false; // AI-GEN - Cursor - GPT4
          } // AI-GEN - Cursor - GPT4
          return true; // AI-GEN - Cursor - GPT4
        }, // AI-GEN - Cursor - GPT4
      },
      {
        title: 'my gaps',
        path: PATH_LEARNING_SCIENCE.general.gaps,
        icon: <TrackChangesIcon />,
        // 2023-08: temporarily disabled until we figure out a way to populate them
        blacklistFunction: (user: AuthUser) => true,
      },
    ],
  },
  {
    subheader: 'admin',
    items: [
      {
        title: 'survey results',
        path: PATH_ADMIN.general.survey_results,
        icon: <Iconify icon={'mdi:clipboard-edit-outline'} width={32} height={32} />,
        blacklistFunction: (user: AuthUser) =>
          user?.alphaAuthAttributes?.role !== Role.ADMIN &&
          user?.alphaAuthAttributes?.role !== Role.GUIDE,
      },
    ],
  },
];
