import { API } from '@aws-amplify/api';
import { IncorrectTestQuestion } from '../../@types/coaching';

export async function getIncorrectQsByAssignmentId({
  assignment_id,
}: {
  assignment_id: string;
}): Promise<IncorrectTestQuestion[]> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = `coaching/get-test-incorrect-question/${assignment_id}`;
    const myInit = {};
    const response = await API.get(apiName, path, myInit);
    return response.result;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
